/*eslint-disable */
// Set webpack public path for async loading.
__webpack_public_path__ = window.themeUrl + 'dist/';

// workaround for ie11 and dynamic import
// @see https://github.com/babel/babel/issues/10140
import 'core-js/modules/es.array.iterator';

// DOM polyfills are not part of core-js.
import 'classlist.js';
import 'element-closest-polyfill';
import 'objectFitPolyfill';

import '@orestbida/iframemanager';
import SmoothAnchors from 'utils/helpers/smooth-anchors';
import Reframe from 'utils/helpers/reframe';
import './main.css';

objectFitPolyfill();

const body = document.body;

const im = window.iframemanager()
im.run({
  currLang: 'en',
  services : {
      googlemaps : {
          embedUrl: 'https://www.google.com/maps/embed?pb={data-id}',
          iframe: {
              allow : 'picture-in-picture; fullscreen;'
          },
          languages : {
              en : {
                  // notice: 'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://cloud.google.com/maps-platform/terms" target="_blank">terms and conditions</a> of Google Maps.',
                  // loadBtn: 'Load map',
                  notice: window.iframenotices.gmap.text,
                  loadBtn: window.iframenotices.gmap.button,
                  // loadAllBtn: "Don't ask again"
              }
          }
      }
  }
});

function logModuleLoadFailed(error) {
  console.warn('An error occurred while loading the component');
  console.error(error);
}

function initStimulPlugins(context) {
  // Init small helpers.
  new SmoothAnchors(context);
  new Reframe(context);

  // Load some components javascript files.
  if (context.querySelector('[data-gmap]')) {
    import(/* webpackChunkName: "gmaps" */ 'components/gmaps/gmaps').then(Gmaps => {
      new Gmaps.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-lightbox]')) {
    import(/* webpackChunkName: "lightbox" */ 'components/lightbox/lightbox').then(Lightbox => {
      new Lightbox.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-menu-toggle]')) {
    import(/* webpackChunkName: "menu-toggle" */ 'components/menu-toggle/menu-toggle').then(MenuToggle => {
      new MenuToggle.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-slideshow]')) {
    import(/* webpackChunkName: "slideshow" */ 'components/slideshow/slideshow').then(Slideshow => {
      new Slideshow.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector("[data-comment-reply]")) {
    import(/* webpackChunkName: "comment" */ 'components/comment/comment').then(Comment => {
        new Comment.default(context);
      }).catch(logModuleLoadFailed);
  }

  // Load extra modules
  if (context.querySelector('.rellax')) {
    import(/* webpackChunkName: "parallax" */ 'utils/helpers/parallax').then(Parallax => {
      new Parallax.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-sticky-header]')) {
    import(/* webpackChunkName: "sticky-header" */ 'utils/helpers/sticky-header').then(StickyHeader => {
      new StickyHeader.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-animation]')) {
    import(/* webpackChunkName: "scroll-animation" */ 'utils/helpers/scroll-animation').then(ScrollAnimation => {
      new ScrollAnimation.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-imagesloaded]')) {
    import(/* webpackChunkName: "imagesloaded" */ 'utils/helpers/imagesloaded').then(ImagesLoaded => {
      new ImagesLoaded.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-masonry]')) {
    import(/* webpackChunkName: "masonry" */ 'utils/helpers/masonry').then(Masonry => {
      context.querySelectorAll('[data-masonry]').forEach(el => {
        new Masonry.default(el);
      })
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-faq]')) {
    import(/* webpackChunkName: "faq" */ 'modules/Faq/Faq').then(Faq => {
      context.querySelectorAll('[data-faq]').forEach(el => {
        new Faq.default(el);
      })
    }).catch(logModuleLoadFailed);
  }

  // Modules & components js
  if (context.querySelector('.media-item')) {
    import(/* webpackChunkName: "MediaItem" */ 'components/media-item/media-item').then(MediaItem => {
      new MediaItem.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('.gform_wrapper')) {
    import(/* webpackChunkName: "Form" */ 'components/form/form').then(Form => {
      new Form.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-cards]')) {
    import(/* webpackChunkName: "Cards" */ 'modules/Cards/Cards').then(Cards => {
      context.querySelectorAll('[data-cards]').forEach(el => {
        new Cards.default(el);
      })
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-worldmap]')) {
    import(/* webpackChunkName: "Cards" */ 'modules/Map/Map').then(Map => {
      context.querySelectorAll('[data-worldmap]').forEach(el => {
        new Map.default(el);
      })
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-fees-pricing]')) {
    import(/* webpackChunkName: "FeesPricing" */ 'modules/FeesPricing/FeesPricing').then(FeesPricing => {
      context.querySelectorAll('[data-fees-pricing]').forEach(el => {
        new FeesPricing.default(el);
      })
    }).catch(logModuleLoadFailed);
  }
}

initStimulPlugins(document.querySelector('html'));
// Make function globally available.
window.initStimulPlugins = initStimulPlugins;
